import {SmartComponent} from "smart-component-js";

class YouWinComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.init();
    }

getPrize(){
    this.code = window.location.href.split("=").pop();
    this.prizes= this.element.querySelectorAll('.prize');

     this.prize= this.prizes.forEach((el) => {

       if(this.code === el.dataset.code ){
           el.classList.remove('hidden');
       }
   })
}

    init() {
        this.getPrize();
    }
}

export default YouWinComponent;