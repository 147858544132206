import {SmartComponent} from "smart-component-js";

class CustomClickComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.init();
    }


    init() {
        const link = this.element.querySelector('.yxpType_LINK a');
        if (!link) return;
        const url = link.href;
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('idReceipt');
        link.setAttribute('href', `${url}?idReceipt=${id}`);
    }
}

export default CustomClickComponent;