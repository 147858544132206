import {SmartComponent} from "smart-component-js";

class ReceiptComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.thankYouPage = this.element.dataset.tyPage;
        this.init();
    }


    toggleLabelVisibility() {
        document.addEventListener('yxp-loyalty-receipt-file-uploaded', () => {
            const firstStep = this.element.querySelector('.first-step');
            const secondStep = this.element.querySelector('.second-step');
            if (!firstStep || !secondStep) return;
            firstStep.classList.add('hidden');
            secondStep.classList.remove('hidden');
            this.element.querySelector('.yxp-rw-step.intro').classList.add('hidden');
        });
    }

    addThankYouPageListener() {
        document.addEventListener("yxp-loyalty-receipt-ok", (e) => {
            this.sendTracking();
            this.element.querySelector('.yxp-rw2-success-wrapper').classList.add('hidden');
            let id = e.detail.body.id;
            this.element.classList.add('loading');
            window.location.href = `${this.thankYouPage}?idReceipt=${id}`;
        });
    }


    sendTracking() {
        try {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "content_upload",
                category: "UPLOAD",
                action: "TICKET",
                label: location.href
            });
            youserxp.tracker.getInstance().send('EVENT', {
                eventCategory: "content_upload",
                eventAction: "UPLOAD",
                eventLabel: location.href
            });
        } catch (e) {
        }
    }

    init() {
        this.toggleLabelVisibility();
        this.addThankYouPageListener();
    }
}

export default ReceiptComponent;