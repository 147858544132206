import {SmartComponent} from "smart-component-js";
import Swiper, {Navigation, Pagination} from "swiper";

class DigitalRewardsComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.isNextMonth = this.element.dataset.isNextMonth;
        const baseurl = '/api/1.0/sofidel/' + window.CAMPAIGN_CODE;
        this.endpoint = this.isNextMonth === 'true' ? (baseurl + '/reward/monthly/next') : (baseurl + '/reward/monthly');
        this.pagination = this.element.querySelector('.swiper-pagination');
        this.prev = this.element.querySelector('.swiper-button-prev');
        this.next = this.element.querySelector('.swiper-button-next');
        this.init();
    }

    initCountdown(expirationDate) {
        const countdownWrappers = this.element.querySelectorAll('.digital-rewards__countdown');
        countdownWrappers.forEach((element) => {
            this.updateCountdown(element, expirationDate);
        });
    }

    updateCountdown(element, countdownDate) {
        const distance = countdownDate > 0 ? countdownDate : 0;
        element.innerHTML = this.getCountdownHtml(distance);
        if (!distance) return;

        let interval = setInterval(() => {
            const distance = countdownDate > 0 ? countdownDate : 0;
            element.innerHTML = this.getCountdownHtml(distance);
            if (distance === 0) clearInterval(interval);
        }, 60000);
    }

    getCountdownHtml(distance) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        return `
            <div class="digital-rewards__countdown-box days">
                <strong>${days}</strong>
                <div>DAYS</div>
            </div>
            <span class="digital-rewards__two-dots">:</span>
            <div class="digital-rewards__countdown-box hours">
                <strong>${hours}</strong>
                <div>HOURS</div>
            </div>
            <span class="digital-rewards__two-dots">:</span>
            <div class="digital-rewards__countdown-box minutes">
                <strong>${minutes}</strong>
                <div>MINUTES</div>
            </div>       
        `;
    }

    initSwiper() {
        this.swiper = new Swiper(this.element.querySelector('.swiper'), {
            modules: [Navigation, Pagination],
            slidesPerView: 1.1,
            spaceBetween: 16,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 32
                }
            },
            pagination: {
                el: this.pagination,
                clickable: true,
                type: 'bullets'
            },
            navigation: {
                nextEl: this.next,
                prevEl: this.prev,
            },
        });
    }

    showNotEnoughPointsElement(points) {
        const pointsWrapper = this.element.querySelector('.digital-rewards__not-enough-point');
        if (!pointsWrapper) return;
        let label = pointsWrapper.dataset.notEnoughLabel;
        pointsWrapper.querySelector('.digital-rewards__description').innerHTML = label.replace('{0}', points);
        pointsWrapper.classList.remove('hidden');
    }

    checkPointsQuantity(pointsToClaim) {
        if (!pointsToClaim) return;
        const points = parseInt(pointsToClaim);
        if (points > 0) this.showNotEnoughPointsElement(points);
    }

    checkCanClaim(canClaim) {
        if (!canClaim) return;
        this.element.querySelector('.digital-rewards__enough-point')?.classList.remove('hidden');
    }

    checkRequestedReward(date) {
        if (!date) return;
        this.element.querySelector('.digital-rewards__not-requested-prize').classList.add('hidden');
        this.element.querySelector('.digital-rewards__date').innerHTML = date;
        this.element.querySelector('.digital-rewards__requested-prize').classList.remove('hidden');
        this.element.querySelector('.digital-rewards__next-month')?.classList.remove('hidden');
    }


    addRewardHtml(data) {
        if (!data.rewards.length) {
            this.element.closest('.purple-gradient-full-wave')?.remove();
            return;
        }
        this.initCountdown(data.nextMonthMilliseconds);
        this.checkPointsQuantity(data.pointsToClaim);
        this.checkCanClaim(data.canClaim);
        this.checkRequestedReward(data.requestRewardDate);

        let slidesHtml = '';
        data.rewards.forEach((reward) => slidesHtml += this.getRewardHtml(reward));
        this.element.querySelector('.swiper-wrapper').insertAdjacentHTML('afterbegin', slidesHtml);
        this.initSwiper();
    }

    getRewardHtml(reward) {
        return `
            <div class="swiper-slide">
                <div class="digital-rewards__image">
                    <img src="${reward.image}" alt="${reward.name}">
                </div>
                <div class="digital-rewards__card generic-card">
                    <h5>${reward.name}</h5>
                    <div class="caption">${reward.description || ''}</div>
                    <div class="digital-rewards__points">${reward.requiredPoints}</div>
                </div>
            </div> `;
    }

    getRewards() {
        youserxp.ajax(this.endpoint, {method: 'GET', auth: true}).then(
            (data) => this.addRewardHtml(data.body),
            (err) => console.log(err),
            () => this.element.classList.remove('loading')
        );
    }

    init() {
        this.getRewards();
    }
}

export default DigitalRewardsComponent;