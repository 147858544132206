import {SmartComponent} from "smart-component-js";

class HomeComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.init();
    }


    init() {
        const userId = this.element.dataset.userId;
        if (!userId) return;
        this.element.querySelectorAll('.not-logged-user-box').forEach(el => el.classList.add('hidden'));
    }
}

export default HomeComponent;