import {SmartComponent} from "smart-component-js";
import Swiper from 'swiper/bundle';

class ProductCarouselComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.wrapper = element;
        this.init();
    }

    init() {
        this.initCarousel();
        this.initCarouselSwiper();
    }

    initCarousel() {
        let currentSwiper = this.wrapper;
        this.swiperElement = currentSwiper.firstElementChild
        this.swiperElement.classList.add('swiper');
        this.swiperElement.firstElementChild.classList.add('swiper-wrapper')
        this.slides = currentSwiper.querySelectorAll('.swiper-wrapper > div');
        this.slides.forEach((slide) => slide.classList.add('swiper-slide'));
        currentSwiper.insertAdjacentHTML('beforeend', `<div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-pagination"></div>`);
    }

    initCarouselSwiper() {
        new Swiper(this.swiperElement, {
            slidesPerView: 1.6,
            centeredSlides: true,
            grabCursor: true,
            keyboard: {
                enabled: true,
            },
            breakpoints: {
                576: {
                    slidesPerView: 2,
                    centeredSlides: false,
                },
                992: {
                    slidesPerView: 3,
                    centeredSlides: false,
                },
                1200: {
                    slidesPerView: 4,
                    centeredSlides: false,
                },

            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        });
    }
}

export default ProductCarouselComponent;