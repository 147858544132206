import {SmartComponent} from "smart-component-js";

class PostRegistrationComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.loginCta = this.element.querySelector('.post-registration-cta');

        this.init();
    }

    sendTracking() {
        try {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "generate_lead",
                category: "FORM CONTACT",
                action: "REQUEST",
                label: "SUBSCRIPTION",
                currency:"EUR",
                value:"0"
            });
            youserxp.tracker.getInstance().send('EVENT', {
                eventCategory: "FORM CONTACT",
                eventAction: "REQUEST",
                eventLabel: "SUBSCRIPTION",
                eventValue: "0",
            });
        } catch (e) {
            console.log("Tracking: " + e);
        }
    }


    init() {
        this.loginCta.addEventListener('click', () => {
            this.sendTracking();
        });

    }
}

export default PostRegistrationComponent;