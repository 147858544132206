import {SmartComponent} from "smart-component-js";

class HeaderComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.menuButton = this.element.querySelector('.header__menu-button');
        this.menuWrapper = this.element.querySelector('.header__menu-wrapper');
        this.init();
    }


    init() {
        this.menuButton?.addEventListener('click', () => {
            this.menuButton.classList.toggle('opened');
            this.menuButton.classList.toggle('closed');
            this.menuWrapper.classList.toggle('opened');
        });
    }
}

export default HeaderComponent;