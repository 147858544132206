import {SmartComponent} from "smart-component-js";

// inserire solo su countries che non vogliono i decimali

class UploadReceiptFixHuComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.init();
    }


    prefillDecimal() {
        const field = this.element.querySelector('input[name="receipt-decimal-amount"]');
        if (!field) return;
        field.value = '00';
        field.parentElement.classList.add('no-decimal');
    }


    init() {
        document.addEventListener('yxp-loyalty-receipt-ready', () => this.prefillDecimal());
    }
}

export default UploadReceiptFixHuComponent;