import {SmartComponent} from "smart-component-js";

class PlayCodeComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;

        this.init();
    }


    takeCode(){
        document.addEventListener("yxp-loyalty-coupon-ok", (e) => {

            this.code = e.detail.body.code;
            this.mission = e.detail.body.missionOutput.RESULT_MISSION

            if (this.mission === undefined){
                window.location.href = `/en/reserved/final-extraction`;
            } else {
                window.location.href = `/en/reserved/instant-win?code=${this.code}`
                this.cta = this.element.querySelector('.cta.go-away');
                this.cta.remove();
            }
        });
    }


    init() {

        this.takeCode();
    }
}

export default PlayCodeComponent;