import {SmartComponent} from "smart-component-js";

class OnBoardingComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.endpoint = `/api/1.0/raas/users/${window.SITE_NAME}`;
        this.init();
    }

    checkConsents(data) {
        this.element.classList.remove('loading');
        const privacyMarketing = data.consents.find(el => el.consentName === 'PRIVACY_MARKETING');
        const privacyProfile = data.consents.find(el => el.consentName === 'PRIVACY_PROFILE');
        if (privacyMarketing && privacyMarketing.consent) {
            const wrapper = this.element.querySelector('div[name="PRIVACY_MARKETING"]');
            this.setConsentsValue(wrapper, privacyMarketing.consent);
        }

        if (privacyProfile && privacyProfile.consent) {
            const wrapper = this.element.querySelector('div[name="PRIVACY_PROFILE"]');
            this.setConsentsValue(wrapper, privacyProfile.consent);
        }

        if (privacyMarketing?.consent && privacyProfile?.consent) {
            this.element.querySelector('form .yxpType_TEXTAREA')?.classList.add('hidden');
        }
    }


    setConsentsValue(wrapper, value) {
        if (!wrapper) return;
        wrapper.closest('.yxpRow').classList.add('hidden');
        const input = wrapper.querySelector('input');
        input.value = value;
        input.click();

    }

    getUserInfo() {
        youserxp.ajax(this.endpoint, {method: 'GET', auth: true}).then(
            (data) => this.checkConsents(data.body),
            (err) => console.log(err)
        );
    }

    init() {
        this.element.classList.add('loading');
        document.addEventListener("yxp-form-loaded", () => this.getUserInfo());

    }
}

export default OnBoardingComponent;
