import {SmartComponent} from "smart-component-js";
import GenericModal from "../utilities/GenericModal";

class MissionModalComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.modal = new GenericModal(this.element);
        this.missionCode = this.element.dataset.mission;
        this.endpoint = `/api/1.0/loyalty/campaign/${window.CAMPAIGN_CODE}/mission/${this.missionCode}/start`;
        this.endpointCompleted = `/api/1.0/loyalty/campaign/${window.CAMPAIGN_CODE}/mission/${this.missionCode}/completed`;

        this.init();
    }

    completeMission() {
        let payload = {
            missionPayload: {
                campaign: window.CAMPAIGN_CODE,
                site: window.SITE_NAME
            }
        }

        youserxp.ajax(this.endpointCompleted, {
            method: 'POST',
            auth: true
        }, payload, "json").then(
            (obj) => console.log(obj),
            (err) => console.error(err)
        );
    }

    missionStart() {
        youserxp.ajax(this.endpoint, {
            method: 'POST',
            auth: true
        }, {}, "json").then((data) => {
            if (data.body.ERROR) return;
            this.modal.show();
            this.completeMission();
        }, (err) => {console.log(err)});
    }

    init() {
        this.missionStart();
    }
}

export default MissionModalComponent;