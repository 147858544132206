import {SmartComponent} from "smart-component-js";


class PrizesRequestComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.redirectUrl = this.element.dataset.redirectUrl;
        this.noResultsLabel = this.element.dataset.noResultsLabel;
        this.button = this.element.querySelector('#redeemPrize');
        this.endpoint = '/api/1.0/sofidel/' + window.CAMPAIGN_CODE + '/reward/request/list';
        this.redeemEndpoint = '/api/1.0/sofidel/' + window.CAMPAIGN_CODE + '/reward/request/';
        this.init();
    }

    init() {
        this.element.classList.add('loading');
        this.getPrizes().then(() => {
            if (this.data.redirect) window.location.href = this.redirectUrl;
            else this.appendPrizes();
        })

        this.cardActivated();
        this.addButtonListener();
    }


    addButtonListener() {
        this.button.addEventListener('click', () => this.redeemPrize());
    }


    redeemPrize() {
        this.element.classList.add('loading');
        const value = document.querySelector('input[name="radio"]:checked').value;
        if (!value) return;
        return youserxp.ajax(this.redeemEndpoint + value, {
            method: 'GET',
            auth: true
        }).then(
            (data) => window.location.href = this.redirectUrl,
            (error) => this.element.classList.remove('loading')
        );
    }


    getPrizes() {
        return youserxp.ajax(this.endpoint, {
            method: 'GET',
            auth: true
        }).then((data) => {
            this.data = data.body
            console.log(this.data)
        })
    }

    appendPrizes() {
        let prizes = "";
        this.data.rewards.forEach((el) => {
            prizes += `      
                <div class="prizes-request__generic-card">
                    <label>
                        <input type="radio" name="radio" class="radio" value="${el.idReward}">
                        <span class="checkmark"></span>
                    </label>
                    <img src="${el.image}" alt="${el.image}" />
                    <h5><b>${el.name}</b></h5>
                    <p>${el.description || ''}</p>
                    <span class="prizes-request__points point-chips-small-inverted">${el.requiredPoints}</span>
                </div>`;
        });

        if (!this.data.rewards.length) {
            this.element.querySelector('.prizes-request__wrapper').insertAdjacentHTML('beforeend', `
                <div class="prizes-request__no-results">${this.noResultsLabel}</div> `);
        } else this.element.querySelector('.prizes-request__wrapper').insertAdjacentHTML('beforeend', prizes);
        this.element.classList.remove('loading');
    }

    cardActivated() {
        this.element.addEventListener("change", () => {
            this.radio = this.element.querySelectorAll('input');
            for (var i = 0; i < this.radio.length; i++) {
                if ($(this.radio[i]).is(':checked')) {
                    $(this.button).removeAttr('disabled');
                    $(this.radio[i]).parents('.prizes-request__generic-card').addClass('border-card');
                } else {
                    $(this.radio[i]).parents('.prizes-request__generic-card').removeClass('border-card');
                }
            }
        })
    }

}

export default PrizesRequestComponent;