import {SmartComponent} from "smart-component-js";

class PrizeRequestConfirmComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.endpoint = '/api/1.0/sofidel/' + window.CAMPAIGN_CODE + '/reward/monthly';
        this.init();
    }

    init() {
        this.getRequestedPrize();
    }

    getRequestedPrize() {
        this.element.classList.add('loading');
        return youserxp.ajax(this.endpoint, {
            method: 'GET',
            auth: true
        }).then((data) => {
            this.appendRewardHtml(data.body.rewards);
            this.element.classList.remove('loading');
        });
    }


    appendRewardHtml(rewards) {
        const reward = rewards[0];
        const rewardHtml = `
            <div class="prize-confirmed">
                <div class="prize-confirmed__image">
                    <img src="${reward.image}" alt="${reward.name}">
                </div>
                <div class="prize-confirmed__card generic-card">
                    <h5>${reward.name}</h5>
                    <div class="caption">${reward.description || ''}</div>
                    <div class="prize-confirmed__points">${reward.requiredPoints}</div>
                </div>
            </div> `;

        this.element.insertAdjacentHTML('afterbegin', rewardHtml);
    }
}

export default PrizeRequestConfirmComponent;