import {SmartComponent} from "smart-component-js";
import Swiper from 'swiper/bundle';

class RewardListComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.wrapper = element;
        this.init();
    }

    init() {
        youserxp.overrideWidget.reward.getRewardItemHtml = function (element, options) {
            const canRewardedClass = element.canRewarded ? 'yxp-can-rewarded' : 'yxp-cannot-rewarded';
            const description = element.extraInfo ? element.extraInfo.description : element.description;
            return `
            <div class="swiper-slide yxp-reward-item ${canRewardedClass}" data-id="${element.id}" 
                data-external-id="${element.externalId}" data-type="${element.type}">
                <div class="yxp-reward-item-wrapper">
                    <div class="yxp-reward-item-image">
                        <img src="${element.extraInfo && element.extraInfo.image ? element.extraInfo.image.mediaUrl : element.previewImage}" alt="${element.name}"/> 
                    </div>
                    <div class="yxp-reward-item-card generic-card">
                        <h5 class="yxp-reward-item-name"><b>${element.extraInfo ? element.extraInfo.name : element.name}</b></h5>
                        <div class="yxp-reward-item-description">${description || ''}</div>
                    </div>
                </div>
            </div>`;
        }
    }
}

export default RewardListComponent;