class GenericModal {
    constructor(element) {
        this.element = element;
        this.close = this.element.querySelector('.modal-close');
        this.init();
    }

    show() {
        this.element.classList.add('open');
        setTimeout(() => this.element.classList.add('pop'), 50);
    }

    hide() {
        this.element.classList.remove('pop');
        setTimeout(() => this.element.classList.remove('open'), 300);
    }

    init() {
        this.close.addEventListener('click', () => {
            this.hide();
        });
    }
}

export default GenericModal;
