import {SmartComponent} from "smart-component-js";

class InstantWinComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.init();
    }


    init() {
        this.element.classList.add('transition-hide');
        this.element.classList.add('loading');
        setTimeout(() => {
            this.element.classList.add('transition-show');
            this.element.classList.remove('transition-hide');
            this.element.classList.remove('loading');
        }, 1200);
    }
}

export default InstantWinComponent;