import {SmartComponent} from "smart-component-js";

class FooterComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.socialButtons = this.element.querySelectorAll('.footer__social-item');

        this.init();
    }

    sendTracking(social) {
        try {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "click_social", category: "SOCIAL", action: "FANPAGE", label: social
            });
            youserxp.tracker.getInstance().send('EVENT', {
                eventCategory: "SOCIAL", eventAction: "FANPAGE", eventLabel: social
            });
        } catch (e) {
            console.log("Tracking: " + e);
        }
    }


    init() {
        this.socialButtons.forEach((button) => {
            button.addEventListener('click', (e) => this.sendTracking(button.dataset.social));
        });
    }
}

export default FooterComponent;